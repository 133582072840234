<template>
  <div class="dialog-form">
    <el-dialog v-model="isShow" title="修改员工信息" width="30%">
      <el-scrollbar class="el-scrollbar" height="400px">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="addStaffRules" label-width="80px" class="demo-ruleForm"
          :size="formSize">
          <el-form-item label="姓名" prop="userRealname">
            <el-input placeholder="请输入真实姓名" v-model="ruleForm.userRealname"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="userMobile">
            <el-input placeholder="请输入手机号" v-model="ruleForm.userMobile"></el-input>
          </el-form-item>
          <el-form-item label="照片" prop="imgUploadRef">
            <upload-img :limit="1" :fileList="staffAvatar" ref="imgUploadRef"
              @handleUploadSuccess="handleImgUploadSuccess" @handleUploadRemove="handleUploadRemove"></upload-img>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="ruleForm.gender" class="m-2" placeholder="选择性别" size="large">
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="部门" prop="dpId">
            <el-select v-model="ruleForm.dpId" class="m-2" placeholder="所属部门" size="large">
              <el-option v-for="item in departmentList" :key="item.dpId" :label="item.dpName" :value="item.dpId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属职位" prop="jobLevel">
            <el-select @change="handleJobLevelChange" v-model="ruleForm.jobLevel" class="m-2" placeholder="选择职位"
              size="large">
              <el-option v-for="item in staffPositionList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属职级" prop="subJobLevel">
            <el-select :disabled="typeof ruleForm.jobLevel == 'number' ? false : true" v-model="ruleForm.subJobLevel"
              class="m-2" :placeholder="typeof ruleForm.jobLevel == 'number' ? '选择职位' : '先选择职位'" size="large">
              <el-option v-for="item in staffChildRankList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="擅长服务" prop="userTags">
            <el-input placeholder="请输入擅长服务,以逗号分隔" v-model="ruleForm.userTags"></el-input>
          </el-form-item>

          <el-form-item label="入职时间" prop="joinTime">
            <el-date-picker v-model="ruleForm.joinTime" type="date" placeholder="请选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="介绍/签名" prop="name">
            <el-input :rows="4" type="textarea" maxlength="100" placeholder="请输入个性签名" show-word-limit
              v-model="ruleForm.userPersonInfo"></el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleSaveClick(ruleFormRef)">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { addStaffRules } from './config'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { updateStaffInfo } from '@/service/main/staff'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    staffId: {
      type: Number,
      default: 1
    }

  },
  components: {
    UploadImg

  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const isShow = ref(props.dialogVisible)
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const staffDetail = computed(() => store.state.staff.staffDetail)
    const staffAvatar = ref([])
    watch(staffDetail, (n) => {
      // let n = newValue.userlist[0]
      if (n != null) {
        ruleForm.userMobile = n.userMobile
        ruleForm.userRealname = n.userRealname
        ruleForm.joinTime = n.joinTime
        ruleForm.userPersonInfo = n.userPersonInfo
        ruleForm.userPhoto = n.userPhoto
        ruleForm.gender = String(n.gender)
        ruleForm.dpId = Number(n.dpId)
        ruleForm.userTags = n.userTags
        ruleForm.jobLevel = Number(n.jobLevel)
        ruleForm.subJobLevel = Number(n.subJobLevel)
        handleJobLevelChange(n.jobLevel)
        staffAvatar.value = [
          {
            name: n.userRealname + '的头像',
            url: n.userPhoto
          }
        ]
      }

    })

    const ruleFormRef = ref()

    const initPage = () => {
      store.dispatch('staff/getStaffPositionAction', COMPANY_ID)
      store.dispatch('staff/getStaffDeparentAction', {
        companyId: COMPANY_ID
      })
      store.dispatch('staff/getStaffDetailAction', [props.staffId, COMPANY_ID])
    }

    const handleUploadRemove = (lastUrl) => {
      ruleForm.userPhoto = ''
      staffAvatar.value = []
      // let obj = {
      //   picId: currentRemove[0].id,
      //   relateKind: 2,
      //   relateId: currentGoodsId.value

      // }
      // store.dispatch('goods/removeGoodsPic', obj)
    }


    watch(() => props.staffId, () => {
      initPage()
    })

    const ruleForm = reactive({
      companyId: COMPANY_ID,
      userMobile: '',
      userRealname: '',
      joinTime: '',
      userPersonInfo: '',
      userPhoto: '',
      gender: '',
      dpId: '',
      jobLevel: '',
      subJobLevel: ''
    })
    const ruleFormRank = ref('')

    const imgUploadRef = ref('')
    const handleImgUploadSuccess = (res) => {
      ruleForm.userPhoto = res.response.data.fileName

    }

    const handleRankChange = (value) => {
      ruleForm.jobLevel = value[0]
      if (value.length == 2) {
        ruleForm.subJobLevel = value[1]
      }
    }

    const emptyForm = () => {
      ruleFormRank.value = ''
      imgUploadRef.value.emptyFileList()
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          if (ruleForm.userPhoto.length == 0) {
            ElMessage({
              message: '请上传员工头像',
              type: 'warning',
            })
            return
          }
          let userTags = ruleForm.userTags
          userTags = userTags.replace(/，/g, ",")
          ruleForm.userTags = userTags

          let obj = { ...ruleForm, userId: props.staffId }
          obj.joinTime = dayjs(obj.joinTime).format('YYYY-MM-DD')
          const res = await updateStaffInfo(obj)
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
          }
          if (res.code == 0) {
            emit('refreshStaffList')
          }
        } else {
          ElMessage({
            message: '带*为必填项,请正确填写数据',
            type: 'warning',
          })
        }
      })


    }

    const staffPositionList = computed(() => store.state.staff.staffPositionList)
    const departmentList = computed(() => store.state.staff.departmentList)
    const staffChildRankList = computed(() => store.state.staff.staffChildRankList)

    const handleJobLevelChange = (value) => {
      if(!value) return
      store.dispatch('staff/getStaffChildRankAction', value)
    }

    return {
      handleJobLevelChange,
      staffPositionList,
      isShow,
      ruleForm,
      imgUploadRef,
      COMPANY_ID,
      handleImgUploadSuccess,
      ruleFormRank,
      handleRankChange,
      handleSaveClick,
      rankProps,
      departmentList,
      genderList,
      staffChildRankList,
      staffDetail,
      staffAvatar,
      UPLOAD_BASE_URL,
      handleUploadRemove,
      addStaffRules,
      ruleFormRef,
      dayjs
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  &:deep(.el-upload) {
    width: 100px;
    height: 100px;


  }

  &:deep(.el-upload--picture-card i) {
    margin-top: 34px;
  }

  &:deep(.el-upload-list) {
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }
  
  &:deep(.el-form-item) {

    .el-select,
    .el-cascader {
      width: 100% !important;
      max-width: 100% !important;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }
}
</style>
