<template>
  <div class="add-rank-dialog">
    <page-dialog
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="关联服务"
    >
      <div class="shop-list">
        <div class="titls">
          <div>服务</div>
          <div>价格</div>
          <div>时长</div>
        </div>
        <el-scrollbar height="250px">
          <div class="serve-list">
            <template v-if="serveList.length > 0">
              <template :key="item" v-for="item in serveList">
                <div @click="handleServeClick(item)" class="item">
                  <div class="left">
                    <div class="name">{{ item.serveName }}</div>
                  </div>
                  <div class="right">
                    <!-- @click="handleCheckbox(item.paraList[0].paraId)" -->
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox
                        @change="handleCheckBoxChange($event,item)"
                        :label="item.serveId"
                      >
                        <div class="checkbox">
                          <div class="salePrice">¥{{ item.salePrice / 100 }}</div>
                          <div class="serveTime">{{ item.serveTimeLength }}分钟</div>
                        </div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <el-empty description="该职级无对应服务" />
            </template>
          </div>
        </el-scrollbar>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { useState } from '@/hooks'
import { ElMessage } from 'element-plus'
import { setStaffServeBind, removeStaffServeBind, getRankServeList } from '@/service/main/staff'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    staffId: {
      type: Number,
      default: 0
    },
    lvid: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const formState = useState('serve', [])
    const checkList = ref([])
    const staffServeBindList = computed(() => store.state.staff.staffServeBindList)
    watch(staffServeBindList, (n) => {
      checkList.value = []
      if (n != null) {
        n.forEach(item => {
          checkList.value.push(item.serveId)
        })
      }
    })

    const currentPage = reactive({
      page: 1,
      pagesize: 10,
    })

    const currentOptServe = reactive({
      id: 0,
      flag: false,
      serveInfo: '',
      paraId: ''
    })

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }


    const serveList = ref([])
    const serveCount = ref(0)
    const initPage = async () => {
      const res = await getRankServeList(props.staffId)
      if (res.code == 0) {
        serveList.value = res.data
      }

      store.dispatch('staff/getStaffServeBindListAction', props.staffId)
    }

    const handleServeClick = (item) => {
      currentOptServe.id = item.serveId
      currentOptServe.serveInfo = item
    }

    const handleCheckbox = (paraId) => {
      currentOptServe.paraId = paraId
    }
    const handleCheckBoxChange = async (flag, item) => {
      currentOptServe.id = item.serveId
      currentOptServe.paraId = item.paraList[0].paraId
      currentOptServe.flag = flag
      // console.log(checkList.value)
      let res
      if (flag) {
        res = await setStaffServeBind(currentOptServe.id, props.staffId, COMPANY_ID, currentOptServe.paraId)
      } else {
        res = await removeStaffServeBind(currentOptServe.id, props.staffId, COMPANY_ID, currentOptServe.paraId)
      }
      if (res.code == 0) {
        store.dispatch('staff/getStaffServeBindListAction', props.staffId)
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
      }
    }

    const handleCheckChange = (value) => {
    }

    const checkBoxClick = (item) => {
      currentOptServe.id = item.serveId
      currentOptServe.serveInfo = item
    }




    watch(() => props.staffId, (n) => {
      if (n != 0) {
        initPage()
      }

    }, {
      deep: true,
      immediate: true
    })

    watch(() => props.lvid, (n) => {
      if (n != 0) {
        initPage()
      }

    }, {
      deep: true,
      immediate: true
    })

    return {
      serveCount,
      serveList,
      handleCheckbox,
      checkBoxClick,
      changeDialogVisible,
      rankCategory,
      ...formState,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckBoxChange,
      handleCheckChange,
      currentOptServe,
      staffServeBindList,
      handleServeClick

    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  .shop-list {
    .serve-list {
      padding: 0 10px;

      .item {
        display: flex;

        .left {
          flex: 1;
        }

        .right {
          flex: 2;

          .checkbox {
            display: flex;

            div {
              flex: 1;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }

            .serveTime {
              position: relative;
              left: -18px;
            }
          }
        }
      }
    }

    .titls {
      display: flex;
      margin-bottom: 20px;
      padding: 0 10px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;
      }
    }

    &:deep(.el-checkbox-group) {
      .forma-list {
        padding-left: 20px;
      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;

            .name {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
