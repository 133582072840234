<template>
  <page-dialog class="reset-password-dialog" title="重置密码" :dialogVisible="dialogVisible"
    @changeDialogVisible="changeDialogVisible" :isShowFooter="true" @handleSaveClick="handleSaveClick">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px"
      class="reset-password-dialog-ruleForm" :size="formSize">
      <el-form-item label="新密码" prop="newPassword">
        <el-input show-password v-model="ruleForm.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="surePassword">
        <el-input show-password v-model="ruleForm.surePassword"></el-input>
        <div class="tips">备注：新密码将通过短信的方式发送到员工</div>
      </el-form-item>
    </el-form>
  </page-dialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    staffId: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {

    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const ruleForm = reactive({
      newPassword: "",
      surePassword: ''
    })

    const handleSaveClick = () => {
      store.dispatch('staff/setStaffPasswordAction', {
        passWord: ruleForm.newPassword,
        userId: props.staffId,
        companyId: COMPANY_ID
      })
      ruleForm.newPassword = ''
      ruleForm.surePassword = ''
    }

    return {
      ruleForm,
      changeDialogVisible,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.reset-password-dialog {

  &:deep(label) {
    font-size: 12px;
  }
}
</style>




